import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { isAddress } from 'ethereum-address';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { BackNext } from '@/components/ui/back-next';
import { useToast } from '@/components/ui/use-toast';
import { WrappedInputSection } from '@/components/ui/wrapped-input-section';
import config from '@/config';
import { chainIdToName } from '@/const';
// import { CreateCompanyDocument } from '@/lib/__generated__/dashboard/graphql';
import { CreateSecurityTokenDocument } from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { cn } from '@/lib/utils';
import useBoundStore, { StoreType } from '@/store';
import { availableMetakeeps, primaryChainId } from '@/store/project-slice';

import ProgressBar from '../ui/progress-bar';
import ProgressInput from '../ui/progress-inputs';

const CreateToken: FunctionComponent = () => {
  const _availableMetakeeps = useBoundStore((state: StoreType) => availableMetakeeps(state));
  const _primaryChainId = useBoundStore((state: StoreType) => primaryChainId(state));

  const [defaultValues, setDefaultValues] = useState<any>({
    chain_id: _primaryChainId?.toString() ?? '0',
    allocations: [
      {
        user_id: '',
        quantity: '',
        percentage: '',
      },
    ],
  });
  const [createLoading, setCreateLoading] = useState<boolean>(false);

  type OutletContextType = [number, React.Dispatch<React.SetStateAction<number>>, string[]];
  const [currentLevel, setCurrentLevel, titles] = useOutletContext<OutletContextType>();
  const { raise_id, company_id } = useParams();
  const methods = useForm({
    defaultValues,
  });
  const formValues = methods.watch();

  const navigate = useNavigate();
  const { handleSubmit } = methods;
  const setSecurityToken = useBoundStore((state) => state.securityTokenSlice.setSecurityToken);

  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { toast } = useToast();

  const stripNonNumberCharacters = (input: string | number): string => {
    const numberString = typeof input === 'string' ? input.replace(/\D/g, '') : input.toString();
    return numberString;
  };

  const availableChains = useMemo(() => {
    return _availableMetakeeps.map((chain_id) => ({
      label: chainIdToName[chain_id ?? 0],
      value: chain_id?.toString(),
    }));
  }, [_availableMetakeeps]);

  const tokenDetails = [
    {
      inputs: [
        {
          type: 'input',
          label: 'Name',
          state: 'name',
          placeholder: 'Enter Name',
          // requiredForNextStep: true,
        },
        {
          type: 'input',
          label: 'Symbol',
          state: 'symbol',
          placeholder: 'Enter Symbol',
          // requiredForNextStep: true,
        },
        {
          type: 'dropdown',
          label: 'Blockchain',
          state: 'chain_id',
          placeholder: 'Select Chain ID',
          options: availableChains,
        },
        {
          type: 'textarea',
          label: 'Description',
          state: 'description',
          placeholder: 'Enter Description',
          // requiredForNextStep: true,
        },
        {
          type: 'imageUpload',
          label: 'Upload image or video token',
          state: 'icon_url',
          placeholder: '',
          noCrop: true,
          // requiredForNextStep: true,
        },
      ],
    },
  ];

  const tokenAllocation = [
    {
      inputs: [
        {
          type: 'numberInput',
          label: 'Total Supply',
          state: 'total_supply',
          placeholder: 'Enter total supply',
          // requiredForNextStep: true,
        },
        {
          type: 'numberInput',
          label: 'Purchase Limit',
          state: 'max_investment',
          placeholder: 'Enter purchase limit',
          // requiredForNextStep: true,
        },
        {
          type: 'currencyInput',
          label: 'Initial Price',
          state: 'initial_price',
          placeholder: 'Enter initial price',
          // requiredForNextStep: true,
        },
      ],
    },
  ];

  const handleCreateToken = async (data) => {
    try {
      console.log('FIRED 1');

      if (currentLevel <= 1) {
        console.log('FIRED 2');
        setCurrentLevel(currentLevel + 1);
      } else {
        console.log('FIRED 3');
        setCreateLoading(true);
        const {
          max_investment,
          initial_price,
          total_supply,
          icon_url,
          description,
          symbol,
          name,
          allocations,
          chain_id,
        } = data;

        const {
          data: { createSecurityToken: createSecurityTokenRes },
        } = await client.mutate({
          mutation: CreateSecurityTokenDocument,
          variables: {
            input: {
              name,
              symbol,
              raise_id: parseInt(`${raise_id}`),
              total_supply: stripNonNumberCharacters(total_supply),
              // decimals
              max_investment: stripNonNumberCharacters(max_investment),
              chain_id: parseInt(chain_id),
              description,
              icon_url: icon_url.hostedUrl,
              initial_price: stripNonNumberCharacters(initial_price),
              allocations:
                allocations[0].user_id === '' || allocations[0].quantity === ''
                  ? []
                  : allocations
                      .filter(({ quantity, user_id }) => user_id && quantity)
                      .map(({ quantity, user_id }) => ({ quantity, user_id })),
            },
          },
        });
        console.log({ createSecurityTokenRes });
        setCreateLoading(false);
        setTimeout(() => {
          setSecurityToken({
            name,
            description,
            icon_url: icon_url.hostedUrl,
            symbol: symbol,
            raise_id,
          });
        }, 1200);
        navigate(`/fundraise/${company_id}?project=round-six&raise=${raise_id}`);
      }
    } catch (err) {
      setCreateLoading(false);
      console.log({ err });
    }
  };

  const [quantityErr, setQuantityErr] = useState('');
  const [userIdErr, setUserIdErr] = useState('');
  const [validWalletAddresses, setValidWalletAddresses] = useState<(boolean | string)[]>([]);

  const isEmailOrAddress = (input) => {
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
    const isAddressInput = isAddress(input);

    return { isEmail, isAddress: isAddressInput };
  };

  const getTotalAllocations = useCallback(() => {
    let total_quantity = 0;
    allAllocations.forEach((user) => {
      total_quantity += user.quantity ? parseFloat(user.quantity) : 0;
    });
    return { total_quantity };
  }, [formValues]);

  const validateInputs = useCallback(
    (data) => {
      let errstring = '';
      console.log(formValues.total_supply);
      const theTotalSupply = parseInt(formValues.total_supply);
      if (getTotalAllocations().total_quantity > theTotalSupply) {
        setQuantityErr(`Quantities cannot be more than ${theTotalSupply}`);
        errstring = `Quantities cannot be more than ${theTotalSupply}`;
      } else {
        setQuantityErr('');
      }
      const allUserIds: string[] = [];
      const validWalletAddresses = allAllocations.map((allo) => {
        const { isEmail, isAddress } = isEmailOrAddress(allo.user_id);
        console.log({ isEmail, isAddress });
        if (allUserIds.includes(allo.user_id)) {
          errstring = `All 'Email or Wallet Address' fields must be unique.`;
          return 'Wallet Addresses must be unique.';
        }
        allUserIds.push(allo.user_id);

        console.log({ quantity1: allo.quantity, isEmail, isAddress });

        if (allo.quantity === '' && allo.user_id) {
          setQuantityErr(`You must enter a quantity`);
          errstring = `You must enter a quantity`;
        }

        if (!!(!isEmail && !isAddress) && allo.quantity) {
          errstring = `All "Email or wallet Address" fields must be either an email or wallet address.`;
          return `Must be either an email or wallet address.`;
        } else {
          return false;
        }
      });
      console.log({ validWalletAddresses });
      setValidWalletAddresses(validWalletAddresses);
      console.log({ errstring });
      return errstring.length > 0 ? errstring : true;
    },
    [getTotalAllocations, validWalletAddresses],
  );

  const allAllocations = useMemo(() => {
    return [...formValues.allocations];
  }, [formValues]);

  const totalAlloactions = useCallback(
    (field_) => {
      let total = 0;
      allAllocations.forEach((user) => {
        if (user[field_]) {
          total += parseFloat(user[field_]);
        }
      });
      return total;
    },
    [allAllocations],
  );

  const handleMaxRangeReached = (new_value, index, royalty_type) => {
    try {
      const royaltyCopy = allAllocations;
      royaltyCopy.splice(index, 1);
      const assignedFees = royaltyCopy.map((a) => a[royalty_type]);
      const sumOfRoyalties = assignedFees.reduce((a, b) => parseFloat(a) + parseFloat(b));
      return parseFloat(sumOfRoyalties) + parseFloat(new_value);
    } catch (err) {
      console.warn('error setting max range reached', err);
      return false;
    }
  };

  useEffect(() => {
    console.log({ formValues, validWalletAddresses });
  }, [formValues, validWalletAddresses]);

  return (
    <form
      className="relative mx-auto flex min-h-[800px] w-full max-w-[800px] flex-col gap-[25px]"
      onSubmit={handleSubmit(handleCreateToken)}
    >
      <h1 className="text-3xl font-semibold"> {titles[currentLevel]} </h1>
      {pageLoading ? (
        <div className="flex h-full items-center justify-center">
          <div className="m-auto size-10 animate-spin rounded-full border-t-2 border-primary" />
        </div>
      ) : (
        <>
          {currentLevel == 0 && (
            <>
              <WrappedInputSection methods={methods} data={tokenDetails} />
            </>
          )}
          {currentLevel == 1 && (
            <>
              <WrappedInputSection
                methods={methods}
                data={[{ inputs: tokenAllocation[0].inputs.slice(0, 3) }]}
              />
              <div className="flex items-center">
                <div className="h-[1px] w-full border-b" />
                <p className="text-nowrap px-[20px] opacity-60"> Set allocation </p>
                <div className="h-[1px] w-full border-b" />
              </div>

              <ProgressBar
                totalAlloactions={totalAlloactions}
                royalties={allAllocations}
                field="percentage"
                unassigned={'Unassigned'}
                isEmailOrAddress={isEmailOrAddress}
              />
              <div className={cn('relative')}>
                {/* inputsDisabled ? 'opacity-70' : '' */}
                {/* {inputsDisabled && (
                  <div className="absolute right-0 top-0 z-[100] size-full cursor-not-allowed" />
                )} */}
                <FormProvider {...methods}>
                  <ProgressInput
                    methods={methods}
                    state={'allocations'}
                    handleMaxRangeReached={handleMaxRangeReached}
                    // totalAlloactions={totalAlloactions}
                    // royaltyType="payout_receivers"
                    otherRoyalties={formValues.royalties2}
                    validateInputs={validateInputs}
                    validWalletAddresses={validWalletAddresses}
                    // setValidWalletAddresses={setValidWalletAddresses}
                    stateField1="user_id"
                    quantity="quantity"
                    total={formValues.total_supply}
                    disabled={true}
                    quantityErr={quantityErr}
                    setQuantityErr={setQuantityErr}
                    userIdErr={userIdErr}
                    setUserIdErr={setUserIdErr}
                    isEmailOrAddress={isEmailOrAddress}
                  />
                </FormProvider>
              </div>
            </>
          )}
          {currentLevel == 2 && (
            <>
              <div className="flex gap-[30px]">
                <img
                  className="size-[160px] rounded-[10px]"
                  src={formValues?.icon_url?.hostedUrl}
                />
                <div className="flex h-[160px] flex-col justify-between">
                  <div className="flex flex-col">
                    <h3 className="text-[26px] font-normal">{formValues?.name}</h3>
                    <p className="opacity-60">{formValues.symbol}</p>
                  </div>

                  <div className="flex gap-[40px]">
                    <div className="flex flex-col">
                      <p className="text-md text-primary">Total Supply</p>
                      <h3 className="text-[22px] font-medium">{formValues.total_supply}</h3>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-md text-primary">Initial Price</p>
                      <h3 className="text-[22px] font-medium">{formValues.initial_price}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <p className="my-[10px]">{formValues.description}</p>
              <ProgressBar
                totalAlloactions={totalAlloactions}
                royalties={allAllocations}
                field="percentage"
                unassigned={'Unassigned'}
                isEmailOrAddress={isEmailOrAddress}
              />
            </>
          )}
        </>
      )}
      <div className="mb-[30px] mt-auto">
        <BackNext
          numOfLevels={3}
          finalBtnText={`CREATE TOKEN`}
          currentLevel={currentLevel}
          setCurrentLevel={setCurrentLevel}
          loading={createLoading}
        />
      </div>
    </form>
  );
};

export default CreateToken;
