import { Project } from '@/lib/__generated__/dashboard/graphql';
import {
  GetMarketplaceInfoDocument,
  MarketplaceInfo,
} from '@/lib/__generated__/marketplace/graphql';
import { client, ClientName } from '@/lib/apollo';
import { lens } from '@dhmk/zustand-lens';

import { StoreType } from '.';

const initialProjectsSliceState: ProjectSliceState = {
  projects: [],
  selectedProject: null,
  selectedProjectMarketplaceInfo: null,
};

export const projectSlice = lens<ProjectSlice>(
  (set, get): ProjectSlice => ({
    ...initialProjectsSliceState,
    setProjects: (projects) => {
      set({ projects }, false, {
        type: 'projectSlice/setProjects',
        projects,
      });
    },
    setSelectedProject: (selectedProject) => {
      set({ selectedProject }, false, {
        type: 'projectSlice/setSelectedProject',
        selectedProject,
      });
    },
    setSelectedProjectMarketplaceInfo: (selectedProjectMarketplaceInfo) => {
      console.log({ selectedProjectMarketplaceInfo });
      set({ selectedProjectMarketplaceInfo }, false, {
        type: 'projectSlice/setSelectedProjectMarketplaceInfo',
        selectedProjectMarketplaceInfo,
      });
    },
    fetchMarketplaceInfo: async () => {
      const selectedProject = get().selectedProject;
      const setSelectedProjectMarketplaceInfo = get().setSelectedProjectMarketplaceInfo;
      if (!selectedProject) return;

      try {
        const data = await client.query({
          query: GetMarketplaceInfoDocument,
          context: { clientName: ClientName.Marketplace },
        });
        setSelectedProjectMarketplaceInfo(data.data.getMarketplaceInfo);
      } catch (error) {
        console.error(error);
      }
    },
    reset: () => {
      set(initialProjectsSliceState, false, {
        type: 'projectSlice/reset',
      });
    },
  }),
);

export const selectMarketplaceClientIsReady = (state: StoreType) =>
  Boolean(state.projectSlice.selectedProject?.graphql_endpoint_url);

export const availableMetakeeps = (state: StoreType) =>
  state.projectSlice.selectedProjectMarketplaceInfo?.available_metakeeps ?? [];

export const primaryChainId = (state: StoreType) =>
  state.projectSlice.selectedProjectMarketplaceInfo?.primary_chain_id ?? 0;

export interface ProjectSliceState {
  projects: Project[];
  selectedProject: null | Project;
  selectedProjectMarketplaceInfo: null | MarketplaceInfo;
}
export interface ProjectSliceActions {
  setProjects: (projects: ProjectSliceState['projects']) => void;
  setSelectedProject: (selectedProject: ProjectSliceState['selectedProject']) => void;
  setSelectedProjectMarketplaceInfo: (
    selectedProjectMarketplaceInfo: ProjectSliceState['selectedProjectMarketplaceInfo'],
  ) => void;
  fetchMarketplaceInfo: () => Promise<void>;
  reset: () => void;
}

export type ProjectSlice = ProjectSliceState & ProjectSliceActions;
